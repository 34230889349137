@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);
.background {
    border-radius: 5px;
    box-shadow: 0px 0px 10px gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
  }
.tablechild{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3px 0px;
  
  
}
.tablechild >p{
  /* max-width: 90px; */
  width: 100%;
  font-weight: bold;
  /* text-align: center; */
}

.status{
  border: 1px solid #ddff00;
  background-color: yellow;
  justify-content: space-around;
}

.spin{
  text-align: center;
}

.preview {
    font-size: 3.8mm;
    line-height: 4.1mm;
    text-align: left;
    padding: 5mm;
    /*width: 100%;*/
    /*height: 100%;*/
    width: 210mm;
    height: 297mm;
    /*background: #61dafb;*/
    /*border: 1px solid black;*/
    /*font-family: ;*/
    position: relative;
}

body {
    /*background: #888;*/
    /*font-family: sans-serif;*/
}

.splash {
    position: fixed;
    top: 40%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center;
}

div {
    /*font-family: 'Source Sans Pro', sans-serif;*/
    /*font-weight: bold;*/
    font-family: 'Radio Canada', sans-serif;
    /*background: grey;    'Source Sans Pro',   */
}

body {
    font-family: 'Radio Canada', sans-serif;
}

.listDoc { background-color: #c2e9ed; color: black; }

.selectedListDoc, .listDoc:hover { background-color: #00b0f0; color: black; }

.togglePrint {
    display: none;
}

@media print {
    body * {
        visibility: hidden;
    }
    .yesprint, .yesprint * {
        visibility: visible;
    }
    .yesprint {
        /*position: fixed;*/
        /*left: 0;*/
        /*top: 0;*/
        padding: 5mm;
        margin: 0;
    }
    .togglePrint {
        display: block;
    }
}

@page {
    size: A4;
    margin: 0;
    padding: 5mm;
}

.sidePads {
    margin: 0;
    padding-left: 2px;
    padding-right: 2px;
    margin-top: 2px;
    margin-bottom: 2px;
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*font-family: 'Source Sans Pro', sans-serif;*/
  font-family: 'Radio Canada', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.imgLogo {
  /*width: 100%;*/
  height: 100%;
  /*padding: 10%;*/
  /*margin: 16px 24px 16px 0;*/
  /*float: left;*/
}

.blink {
  -webkit-animation: blink 1s linear infinite;
          animation: blink 1s linear infinite;
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 0;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 0;
  }
}

.tableSalesRow {
  cursor: pointer;
  /*font-size: 13px;*/
}

.tableSalesCol {
  padding: 0;
}

.genericBold {
  font-weight: bold;
}

.fullHeight {
  height: 100%;
}

.btnCustom2 {
  background: #303f9f;
  /*background: darkslateblue;*/
  color: white;
  border-radius: 6px;
  font-weight: bold;
  border: 0;
}

.btnCustom2:hover, .btnCustom2:focus {
  background: #666ad1;
  color: white;
  border-radius: 6px;
  font-weight: bold;
  border: 0;
}

.btnCustom3 {
  /*padding: 4px;*/
  border: 2px solid #eee;
  border-radius: 6px;
  /*font-size: 14px;*/
}

.btnCustom3:hover {
  /*padding: 4px;*/
  border: 2px solid #9fa8da;
  border-radius: 6px;
  /*font-size: 14px;*/
}

.btnCustom4 {
  /*padding: 4px;*/
  border: 2px solid #eee;
  border-radius: 6px;
  /*font-size: 14px;*/
}

.borderLightGrey {
  border: 2px solid lightgrey;
}

.btnCustom4:hover {
  border: 2px solid #a5d6a7;
}

.hoverRedBorder:hover {
  border: 2px solid #ef9a9a;
}

.hoverOrangeBorder:hover {
  border: 2px solid #ffa726;
}

.hoverBlueBorder:hover {
  border: 2px solid blue;
}

.hoverGreenBackground {
  background: green;
  color: white;
}

.hoverGreenBackground:hover, .hoverGreenBackground:focus {
  background: #25D366;
  color: white;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
  padding: 8px 8px;
}

.itemsTable thead[class*="ant-table-thead"] th{
  /*background-color: #616161 !important;*/
  /*color: white;*/
  color: grey;
  font-weight: bold;
}

.menuWrapper {
  padding: 8px;
  border-radius: 8px;
}

.customHover:hover {
  background: #bbdefb;
  border-radius: 8px;
}

.colorRadio {
  color: white;
  padding: 4px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;
  margin-right: 16px;
}

.templatePreview {
  float: left;
  margin: 8px;
  border-radius: 12px;
  padding: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-right: 16px;
  /*border: 2px solid lightgrey;*/
  background: #eee;
}

.btnCustomOrange {
  background: #c67100;
  /*background: darkslateblue;*/
  color: white;
  border-radius: 6px;
  font-weight: bold;
  border: 0;
}

.btnCustomOrange:hover, .btnCustomOrange:focus {
  background: #ffa000;
  border: 0;
  color: white;
  border-radius: 6px;
  font-weight: bold;
}

.customBorder {
  border-radius: 8px;
}

.outletDiv {
  /*height: 500px;*/
  height: calc(100vh - 40px - 40px);
}

.ledgerDetailsGroup {
  /*background: #e3f2fd;*/
  padding: 8px;
  margin-bottom: 16px;
  border-radius: 8px;
  border: 1px solid lightgrey;
}

.ledgerFormItem {
  margin-bottom: 12px;
}


.itemLineCancelBtn {
  width: 40%;
  background: #f44336;
  color: white;
  padding-left: 12px;
  padding-right: 12px;
  border: none;

}

.itemLineCancelBtn:hover, .itemLineCancelBtn:focus {
  background: #ba000d;
  color: white;
}

.itemLineSaveBtn {
  width: 40%;
  background: forestgreen;
  color: white;
  padding-left: 12px;
  padding-right: 12px;
  border: none;
}

.itemLineSaveBtn:hover, .itemLineSaveBtn:focus {
  background: darkgreen;
  color: white;
}

.itemLineAddBtn {
  width: 100%;
  background: forestgreen;
  color: white;
  padding-left: 8px;
  padding-right: 8px;
  border: none;
}

.itemLineAddBtn:hover, .itemLineAddBtn:focus {
  background: darkgreen;
  color: white;
}

.zeroMargin {
  margin: 0;
}


.sideMenu .ant-menu-item {
  height: 31px;
  line-height: 31px;
  font-size: 15px;
}

.sideMenu .ant-menu-item:not(:last-child) {
  margin-bottom: 1px;
}

.ant-menu-item .anticon + span {
  margin-left: 6px;
}

.ant-menu:not(.ant-menu-horizontal) .sideMenuItem.ant-menu-item-selected {
  background: #01579b;
  color: white;
  border-radius: 8px;
}


.ant-menu-inline .sideMenuItem.ant-menu-item-selected::after {
  border: 0;
}

.sideMenuItem.ant-menu-item:hover {
  background: #bbdefb;
  border-radius: 8px;
  color: black;
}

.sideMenuItem.ant-menu-item:not(.ant-menu-item-selected) a:hover {
  color: black;
}

.sideMenuItem.ant-menu-item-selected a, .sideMenuItem.ant-menu-item-selected a:hover {
  color: white;
}

.sideMenuItem.ant-menu-light .ant-menu-item:not(.ant-menu-item-selected):hover, .sideMenuItem.ant-menu-light .ant-menu-item-active {
  color: black;
}


.tabsLedgers {
  font-weight: bold;
  color: #01579b;
  font-size: 16px;
}

