.background {
    border-radius: 5px;
    box-shadow: 0px 0px 10px gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
  }
.tablechild{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3px 0px;
  
  
}
.tablechild >p{
  /* max-width: 90px; */
  width: 100%;
  font-weight: bold;
  /* text-align: center; */
}

.status{
  border: 1px solid #ddff00;
  background-color: yellow;
  justify-content: space-around;
}

.spin{
  text-align: center;
}
