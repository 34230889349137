.preview {
    font-size: 3.8mm;
    line-height: 4.1mm;
    text-align: left;
    padding: 5mm;
    /*width: 100%;*/
    /*height: 100%;*/
    width: 210mm;
    height: 297mm;
    /*background: #61dafb;*/
    /*border: 1px solid black;*/
    /*font-family: ;*/
    position: relative;
}

body {
    /*background: #888;*/
    /*font-family: sans-serif;*/
}

.splash {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

div {
    /*font-family: 'Source Sans Pro', sans-serif;*/
    /*font-weight: bold;*/
    font-family: 'Radio Canada', sans-serif;
    /*background: grey;    'Source Sans Pro',   */
}

body {
    font-family: 'Radio Canada', sans-serif;
}

.listDoc { background-color: #c2e9ed; color: black; }

.selectedListDoc, .listDoc:hover { background-color: #00b0f0; color: black; }

.togglePrint {
    display: none;
}

@media print {
    body * {
        visibility: hidden;
    }
    .yesprint, .yesprint * {
        visibility: visible;
    }
    .yesprint {
        /*position: fixed;*/
        /*left: 0;*/
        /*top: 0;*/
        padding: 5mm;
        margin: 0;
    }
    .togglePrint {
        display: block;
    }
}

@page {
    size: A4;
    margin: 0;
    padding: 5mm;
}

.sidePads {
    margin: 0;
    padding-left: 2px;
    padding-right: 2px;
    margin-top: 2px;
    margin-bottom: 2px;
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
}
